// Generated by Framer (ce7f419)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["waCcMIvCb", "xR5zcvKWv"];

const variantClassNames = {waCcMIvCb: "framer-v-1l9vphi", xR5zcvKWv: "framer-v-18v82ot"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Minus: "xR5zcvKWv", Plus: "waCcMIvCb"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "waCcMIvCb", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "waCcMIvCb", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16vb438 = activeVariantCallback(async (...args) => {
if (XKaY3MrGu) {
const res = await XKaY3MrGu(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mcEz7", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1l9vphi", className)} data-framer-name={"Plus"} data-highlight layoutDependency={layoutDependency} layoutId={"waCcMIvCb"} onTap={onTap16vb438} ref={ref} style={{...style}} {...addPropertyOverrides({xR5zcvKWv: {"data-framer-name": "Minus"}}, baseVariant, gestureVariant)}><motion.div className={"framer-f6fiew"} data-framer-name={"Horizontal"} layoutDependency={layoutDependency} layoutId={"mwLLeD0JZ"} style={{backgroundColor: "var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}}/><motion.div className={"framer-bfwtj4"} data-framer-name={"Vertical"} layoutDependency={layoutDependency} layoutId={"fAgFFnoJ8"} style={{backgroundColor: "var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{xR5zcvKWv: {rotate: -90}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mcEz7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mcEz7 .framer-eaj8vg { display: block; }", ".framer-mcEz7 .framer-1l9vphi { cursor: pointer; height: 20px; overflow: hidden; position: relative; width: 20px; }", ".framer-mcEz7 .framer-f6fiew { flex: none; height: 1px; left: calc(50.00000000000002% - 16px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 1px / 2); width: 16px; will-change: var(--framer-will-change-override, transform); }", ".framer-mcEz7 .framer-bfwtj4 { flex: none; height: 16px; left: calc(50.00000000000002% - 1px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 16px / 2); width: 1px; will-change: var(--framer-will-change-override, transform); }", ".framer-mcEz7.framer-v-18v82ot .framer-1l9vphi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 20px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xR5zcvKWv":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables false
 */
const FramerfeMswRU2n: React.ComponentType<Props> = withCSS(Component, css, "framer-mcEz7") as typeof Component;
export default FramerfeMswRU2n;

FramerfeMswRU2n.displayName = "Icon Copy";

FramerfeMswRU2n.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerfeMswRU2n, {variant: {options: ["waCcMIvCb", "xR5zcvKWv"], optionTitles: ["Plus", "Minus"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerfeMswRU2n, [])